
<template>
  <v-app id="app">

    <AppNavigation  />
    <v-app-bar
      :clipped-left="$vuetify.breakpoint.lgAndUp"
      app
      color="blue darken-4"
      dark
      style="height: 60px"
      >
      
      <v-toolbar-title>
        <v-app-bar-nav-icon @click.stop="drawer = !drawer"></v-app-bar-nav-icon>
        {{title}}
      </v-toolbar-title>
      <div class="flex-grow-1"></div>
      
      <v-toolbar-items>

        <!-- <v-btn 
          text
          v-if="$store.state.isUserLoggedIn"
          @click="userDetails">
          <span>{{ this.$store.getters.NeedFullUser }}</span>
        </v-btn> -->

        <v-btn
          text
          v-if="$store.state.isUserLoggedIn"
          @click="companyDetails">
          <span>{{ this.$store.state.companyName }}</span>
        </v-btn>

        <v-btn
          text
          v-if="$store.state.isUserLoggedIn"
          @click="companyDetails2">
          <span>{{ this.$store.state.businessYear }}</span>
        </v-btn>
<!-- 
        <v-btn
          text
          v-if="$store.state.isUserLoggedIn"
          @click="langDetails">
          <span>{{ this.$store.state.language }}</span>
        </v-btn> -->

        <!-- <v-btn
          text
          v-if="!$store.state.isUserLoggedIn"
          @click="login">
          Login
        </v-btn> -->

        <v-btn
          text
          v-if="$store.state.isUserLoggedIn"
          @click="switchLog">
          <v-icon color="#ffffff">mdi-account-circle-outline</v-icon>
        </v-btn>    
        <!-- <v-btn
          text
          v-if="$store.state.isUserLoggedIn"
          @click="logout">
          Log Out
        </v-btn>      -->

       </v-toolbar-items>
    </v-app-bar>
    
    <v-main>
      <v-container fluid class="pt-0 pl-0 pr-1">
          <v-dialog
            v-model="showLogOut"
            max-width="350px"
          >
            <v-card>
              <v-card-text>
                <v-row dense>
                  <v-col cols="12" >
                    <span class="headline">{{langC.User}} </span>
                  </v-col>
                </v-row>
                 <v-row dense>
                  <v-col cols="12" >
                    <span class="headline"> {{ this.$store.getters.NeedFullUser }}</span>
                  </v-col>
                </v-row>

                <v-divider class="mt-3 mb-3"></v-divider>

                <v-row>
                  <v-col cols="12" >
                    <v-btn color="primary" block @click="logout()">Logout</v-btn>
                  </v-col>
                </v-row>
              </v-card-text>
              <v-card-actions>
                
              </v-card-actions>
            </v-card>
          </v-dialog>

          <router-view :key="$route.fullPath"></router-view>
      </v-container>
    </v-main>

    <v-footer :fixed="fixed" app>
      <span><v-btn text href="https://roboot.net" target="_blank" class="ma-0 pa-0">CE grupa</v-btn> &copy; 2024, v.0.9.25 </span><span v-if="$store.state.isUserLoggedIn">&nbsp;&nbsp;,{{ this.$store.getters.NeedFullUser }}</span><span v-if="$store.state.isUserLoggedIn">&nbsp;&nbsp;<v-btn text @click="langDetails">{{ this.$store.state.language }}</v-btn></span>
      <v-spacer></v-spacer>

    </v-footer>
  </v-app>
</template>

<script>
import langEn from './components/mainDescEN'
import langHr from './components/mainDescHR'
import commEn from '@/language/commonEN'
import commHr from '@/language/commonHR'
import AppNavigation from './AppNavigation'
export default {
  name: 'App',
  components: {
      AppNavigation
  },
  data: () => ({
    clipped: false,
    drawer: true,
    fixed: false,
    miniVariant: false,
    right: true,
    rightDrawer: false,
    title: 'Roboot.net',
    mainDashboard: 'Dashboard',
    items: [],
    lang: {},
    langC: {},
    currentPath: '',
    joppd: 0,
    group: null,
    showLogOut: false
  }),
  mounted () {
    if (this.$store.state.language === 'EN') {
      this.lang = langEn
      this.langC = commEn
    }
    if (this.$store.state.language === 'HR') {
      this.lang = langHr
      this.langC = commHr
    }
    this.mainDashboard = this.lang.Dashboard
    this.$store.dispatch('setMenuShow', this.drawer)
     
  },
  computed: {
    menuItems () {
      let menuItems = [
        // { icon: 'face', title: 'Login', link: '/users/login' },
        // { icon: 'face', title: 'Sign up', link: '/users/signup' },
    // ]
    // if (this.userIsAuthenticated ) {
    //   menuItems = [
        { icon: 'face', title: 'Radna ploča', link: '/crm/home' }
        // { icon: 'person', title: 'Sign Out', link: '/users/signout' }
      ]
      // }
      return menuItems
    },
    // userIsAuthenticated () {
        // return this.$store.getters.user !== null && this.$store.getters.user !== undefined
    // }
  },
  watch: {
    'drawer' () {
      this.$store.dispatch('setMenuShow', this.drawer)
    }
  },
  methods: {
    switchLog() {
      if (this.$store.state.isUserLoggedIn === true) {
        this.showLogOut = !this.showLogOut
      }
    },
    logout () {
      this.showLogOut = false
      this.$store.dispatch('setBusinessYear', null)
      this.$store.dispatch('setCompanyName', null)
      this.$store.dispatch('setCompanyid', null)
      this.$store.dispatch('setCompanyActiv', null)
      this.$store.dispatch('setToken', null)
      this.$store.dispatch('setUser', null)
      this.$store.dispatch('isUserLoggedIn', false)
      this.$store.dispatch('setDateItems', null)
      this.$store.dispatch('setDatePartners', null)
      this.$store.dispatch('setDateAccounts', null)
      this.$router.push({
        path: '/'
      }).catch(err => {
          // eslint-disable-next-line
          console.log(err);
        })
    },
    changeDrawer() {
      if (this.$store.state.isUserLoggedIn){
        this.drawer = !this.drawer
        console.log('Mijenjam drawer', this.drawer)
      }
    },
    login () {
      this.$router.push({
        path: '/'
      }).catch(err => {
          // eslint-disable-next-line
          console.log(err);
        })
    },
    userDetails () {
      if (this.$store.state.user) {
        // this.$store.dispatch('setUserid', this.$store.state.user.id)
        this.$router.push({
          name: 'userview'
        }).catch(err => {
          // eslint-disable-next-line
          console.log(err);
        })
      }
    },
 
    companyDetails () {
      if (this.$store.state.companyid) {
        this.$router.push({
          name: 'companyselect'
        }).catch(err => {
          // eslint-disable-next-line
          console.log(err);
        })
      }
    },
    companyDetails2 () {
      if (this.$store.state.companyid) {
        this.$router.push({
          name: 'companybusinessyearselect'
        }).catch(err => {
          // eslint-disable-next-line
          console.log(err);
        })
      }
    },
    langDetails () {
      if (this.$store.state.language) {
        this.$router.push({
          name: 'languageselect'
        }).catch(err => {
          // eslint-disable-next-line
          console.log(err);
        })
      }
    }
  }
};
</script>